// SideMenu.tsx
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SelectContent from './SelectContent';
import MenuContent from './MenuContent';
import CardAlert from './CardAlert';
import OptionsMenu from './OptionsMenu';
import { useState, useEffect } from 'react';

const drawerWidth = 300;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
});

interface SideMenuProps {
  onSelectPage: (page: string) => void;
}

export default function SideMenu({ onSelectPage }: SideMenuProps) {
  const [open, setOpen] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    // LocalStorage'dan `business_name` ve `email` verilerini al
    const storedBusinessName = localStorage.getItem('business_name');
    const storedEmail = localStorage.getItem('email');
    if (storedBusinessName) setBusinessName(storedBusinessName);
    if (storedEmail) setEmail(storedEmail);
  }, []);

  const handleDrawerChange = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        display: { xs: 'none', md: 'block' },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mt: 'calc(var(--template-frame-height, 0px) + 4px)',
          p: 1.5,
        }}
      >
        <SelectContent />
      </Box>
      <Divider />

      <MenuContent onSelectPage={onSelectPage} />
      <CardAlert />
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: 'center',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Avatar
          sizes="small"
          alt={businessName || 'İşletme Adı'}
          src="https://toppng.com/uploads/preview/app-icon-set-login-icon-comments-avatar-icon-11553436380yill0nchdm.png"
          sx={{ width: 36, height: 36 }}
        />
        <Box sx={{ mr: 'auto' }}>
          <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
            {businessName || 'İşletme Adı'}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {email || 'Email Adresi'}
          </Typography>
        </Box>
        <OptionsMenu />
      </Stack>
    </Drawer>
  );
}
