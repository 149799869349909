import { API_BASE_URL } from '../config';

export const registerUser = async (data: any) => {
  const response = await fetch(`${API_BASE_URL}/auth/register`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error('Kayıt başarısız');
  }
};

export const fetchLocationDetails = async (query: string) => {
  const response = await fetch(`${API_BASE_URL}/places_api/autocomplete_business/?input_text=${query}`);
  if (!response.ok) {
    throw new Error('Lokasyon detayları alınamadı');
  }
  const data = await response.json();
  return data.suggestions || [];
};
