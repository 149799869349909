import { fetchWithAuth } from '../api/fetchWrapper'; // Merkezi fetch fonksiyonu

// Profil verisini al
export const getProfileData = async () => {
  try {
    const response = await fetchWithAuth('/profile/', { method: 'GET' });

    if (!response.ok) {
      throw new Error('Profil verisi alınamadı');
    }

    return await response.json();
  } catch (error) {
    console.error('Profil verisi alınırken hata oluştu:', error);
    throw error;
  }
};

// Telefon numarasını güncelle
export const updatePhone = async (phone: string) => {
  try {
    const response = await fetchWithAuth(`/profile/phone?phone=${phone}`, { method: 'PUT' });

    if (!response.ok) {
      throw new Error('Telefon numarası güncellenemedi');
    }

    const data = await response.json();
    return data.phone;
  } catch (error) {
    console.error('Telefon numarası güncellenirken hata oluştu:', error);
    throw error;
  }
};

// Şifreyi güncelle (Mock fonksiyon)
export const updatePassword = async (password: string) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(password);
    }, 1000);
  });
};

// Aboneliği iptal et (Mock fonksiyon)
export const cancelSubscription = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('Abonelik iptal edildi.');
    }, 1000);
  });
};
