import * as React from 'react';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Typography, Card, CardContent, Divider, useMediaQuery, useTheme, Snackbar, Alert } from '@mui/material';
import dayjs from 'dayjs';
import CustomDatePicker from './CustomDatePicker';
import EditIcon from '@mui/icons-material/Edit';
import { fetchData, updateCardName, CardData } from '../../api/dataGridApi';
import ClearIcon from '@mui/icons-material/Clear';

export default function CustomizedDataGrid() {
  const [open, setOpen] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState<GridCellParams | null>(null);
  const [newCardName, setNewCardName] = React.useState("");
  const [selectedRange, setSelectedRange] = React.useState({ startDate: null, endDate: null });
  const [rows, setRows] = React.useState<CardData[]>([]);
  const [filteredRows, setFilteredRows] = React.useState<CardData[]>([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [stats, setStats] = React.useState({
    totalUniqueIpCount: 0,
    totalNfcUniqueIpCount: 0,
    totalQrUniqueIpCount: 0,
    averageUniqueIpCountPerCard: 0,
  });
  const [notification, setNotification] = React.useState({ open: false, message: '', severity: 'success' });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const clearFilters = () => {
    setSelectedRange({ startDate: null, endDate: null }); // Tarihi temizler
    setSearchTerm(''); // Arama terimini temizler
    setRows([]); // Tablodaki veriyi sıfırlar
  };

  React.useEffect(() => {
    async function loadData() {
      setLoading(true);
      setError(null);
      try {
        const response = await fetchData(
          selectedRange.startDate ? selectedRange.startDate.format('YYYY-MM-DD') : undefined,
          selectedRange.endDate ? selectedRange.endDate.format('YYYY-MM-DD') : undefined
        );
        console.log(response);
        setRows(response.cards);
        setFilteredRows(response.cards);

        // İstatistikleri güncelle
        setStats({
          totalUniqueIpCount: response.total_unique_client_count,
          totalNfcUniqueIpCount: response.total_nfc_unique_client_count,
          totalQrUniqueIpCount: response.total_qr_unique_client_count,
          averageUniqueIpCountPerCard: response.average_unique_client_count_per_card,
        });
      } catch (error) {
        console.error("Error loading data:", error);
        setError("Failed to load data");
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [selectedRange]);

  React.useEffect(() => {
    if (searchTerm) {
      setFilteredRows(
        rows.filter((row) =>
          row.card_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.card_id.toString().includes(searchTerm) // ID ile eşleşmeyi kontrol et
        )
      );
    } else {
      setFilteredRows(rows);
    }
  }, [searchTerm, rows]);

  const handleOpenDialog = (params: GridCellParams) => {
    setSelectedCard(params);
    setNewCardName(params.row.card_name);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedCard(null);
  };

  const handleSave = async () => {
    if (selectedCard) {
      try {
        // API ile kart adını güncelle
        await updateCardName(selectedCard.row.card_id, newCardName);

        // rows ve filteredRows'u güncelle
        const updatedRows = rows.map((row) =>
          row.card_id === selectedCard.row.card_id
            ? { ...row, card_name: newCardName }
            : row
        );
        setRows(updatedRows);
        setFilteredRows(updatedRows);

        // Başarılı güncelleme bildirimi göster
        setNotification({ open: true, message: 'Kart adı başarıyla güncellendi', severity: 'success' });
      } catch (error) {
        console.error('Kart adı güncellenirken bir hata oluştu:', error);
        setNotification({ open: true, message: 'Kart adı güncellenirken bir hata oluştu', severity: 'error' });
      }
    }
    handleCloseDialog();
  };

  const handleDatePick = (startDate, endDate) => {
    const formattedStartDate = startDate ? dayjs(startDate) : null;
    const formattedEndDate = endDate ? dayjs(endDate) : null;
    setSelectedRange({ startDate: formattedStartDate, endDate: formattedEndDate });
  };

  const handleNotificationClose = () => {
    setNotification({ ...notification, open: false });
  };

  const columns: GridColDef[] = [
    { field: 'card_name', headerName: 'Kart ismi', flex: 1, editable: true },
    { field: 'card_id', headerName: 'Kart ID', flex: 0.5, sortable: false },
    {
      field: 'unique_client_count',
      headerName: 'Okunma Sayısı',
      flex: 0.8,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'actions',
      headerName: 'Düzenle',
      flex: 0.2,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <IconButton
          color="primary"
          size="small"
          onClick={() => handleOpenDialog(params)}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 3 }}>
      {/* Özet Kart */}
      <Card sx={{ maxWidth: '100%', padding: 0 }}>
        <CardContent sx={{ padding: 2 }}>
          <Typography variant="h5" fontWeight="bold" align="center" gutterBottom>
            Kart İstatistikleri
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box display="flex" flexDirection="column" gap={2} sx={{ px: 2, mb: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1">Toplam Okunma</Typography>
              <Typography variant="h6" color="primary">{stats.totalUniqueIpCount}</Typography>
            </Box>
            <Divider />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1">NFC</Typography>
              <Typography variant="h6" color="primary">{stats.totalNfcUniqueIpCount}</Typography>
            </Box>
            <Divider />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1">QR</Typography>
              <Typography variant="h6" color="primary">{stats.totalQrUniqueIpCount}</Typography>
            </Box>
            <Divider />
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Kart Başına Ortalama</Typography>
              <Typography variant="h6" color="primary">{stats.averageUniqueIpCountPerCard}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {/* Tarih ve Arama */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
          '@media (max-width: 600px)': {
            width: '100%',
            flexDirection: 'column',
          },
        }}
      >
        {/* Tarih seçici */}
        <CustomDatePicker
          onDatePick={(date) => handleDatePick(date.startDate, date.endDate)}
        />

        {/* Arama ve Temizleme Butonu */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
            flexWrap: 'wrap',
            width: '100%',
            '@media (max-width: 600px)': {
              flexDirection: 'row',
              gap: 1,
            },
          }}
        >
          {/* Arama Kutusu */}
          <TextField
            label="Ara"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              flexGrow: 1,
              minWidth: '150px',
              '@media (max-width: 600px)': {
                width: 'calc(100% - 50px)',
                mt: 1,
              },
            }}
          />

          {/* Temizleme Butonu */}
          <IconButton
            onClick={clearFilters}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 30,
              height: 30,
              border: '1px solid #ccc',
              borderRadius: '50%',
              '@media (max-width: 600px)': {
                width: 30,
                height: 30,
              },
            }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Tablo */}
      {loading ? (
        <Typography align="center">Yükleniyor...</Typography>
      ) : error ? (
        <Typography color="error" align="center">{error}</Typography>
      ) : filteredRows.length > 0 ? (
        <DataGrid
          disableColumnFilter
          rows={filteredRows}
          columns={columns}
          getRowId={(row) => row.card_id}
          disableColumnMenu
          autoHeight
          pageSizeOptions={[5, 10, 20]}
          sx={{
            width: '100%',
            '& .MuiDataGrid-cell': { padding: '4px' },
            '& .MuiDataGrid-columnHeaders': { minHeight: 32 },
            '& .MuiDataGrid-row': { minHeight: 32 },
            '@media (max-width: 600px)': {
              fontSize: '0.75rem',
              '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
                padding: '4px',
                minWidth: 0,
              },
            },
          }}
        />
      ) : (
        <Typography align="center">Sisteme Kayıtlı Kartınız Bulunmamaktadır</Typography>
      )}

      {/* Dialog */}
      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth={isMobile ? 'xl' : 'sm'}>
        <DialogTitle>Kartı Düzenle</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Yeni Kart ismi"
            type="text"
            fullWidth
            variant="outlined"
            value={newCardName}
            onChange={(e) => setNewCardName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            İptal
          </Button>
          <Button onClick={handleSave} color="primary">
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>

      {/* Notification */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert onClose={handleNotificationClose} severity={notification.severity as any} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
