import React, { useState } from 'react';
import { TextField, Box, Typography, ClickAwayListener, Paper } from '@mui/material';
import { API_BASE_URL } from '../../config';

interface LocationSearchProps {
  locationQuery: string;
  setLocationQuery: (query: string) => void;
  setSelectedLocationDetails: (details: any) => void;
}

const LocationSearch: React.FC<LocationSearchProps> = ({ locationQuery, setLocationQuery, setSelectedLocationDetails }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [open, setOpen] = useState(false);

  const handleLocationSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setLocationQuery(query);

    if (query.length > 2) {
      setOpen(true);
      try {
        const response = await fetch(`${API_BASE_URL}/places_api/autocomplete_business/?input_text=${query}`);
        const data = await response.json();
        setSearchResults(Array.isArray(data.suggestions) ? data.suggestions : []);
      } catch (error) {
        console.error("Autocomplete request failed:", error);
        setSearchResults([]);
      }
    } else {
      setSearchResults([]);
      setOpen(false);
    }
  };

  const handleLocationSelect = async (location: any) => {
    try {
      const response = await fetch(`${API_BASE_URL}/places_api/business_details/?place_id=${location.place_id}`);
      const details = await response.json();
      setSelectedLocationDetails(details);
      setSearchResults([]);
      setLocationQuery(location.description);
      setOpen(false);
    } catch (error) {
      console.error("Business details request failed:", error);
    }
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: 'relative' }}>
        <TextField
          fullWidth
          id="location"
          placeholder="Lokasyon adı girin"
          value={locationQuery}
          onChange={handleLocationSearch}
        />
        {open && (
          <Paper
            elevation={3}
            sx={{
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              maxHeight: '150px',
              overflowY: 'auto',
              mt: 1,
              zIndex: 1,
            }}
          >
            {searchResults.length > 0 ? (
              searchResults.map((result, index) => (
                <Typography
                  key={index}
                  onClick={() => handleLocationSelect(result)}
                  sx={{ cursor: 'pointer', padding: 1, borderBottom: '1px solid #ddd' }}
                >
                  {result.description}
                </Typography>
              ))
            ) : (
              <Typography sx={{ padding: 1, textAlign: 'center', color: 'gray' }}>
                Sonuç bulunamadı
              </Typography>
            )}
          </Paper>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default LocationSearch;
