import React from 'react';
import { Card, CardContent, CardMedia, CardActions, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DEFAULT_IMAGE_URL = '/feedup_nfc.jpg';

const EnterpriseCard = () => {
  const navigate = useNavigate();

  return (
    <Card
      variant="outlined"
      sx={{ borderRadius: 2, boxShadow: 3, display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <CardMedia
        component="img"
        sx={{ height: 220, objectFit: 'cover' }}
        image={DEFAULT_IMAGE_URL}
        alt="Enterprise Package"
      />
      <CardContent sx={{ textAlign: 'center', flexGrow: 1 }}>
        <Typography variant="h5" color="primary" fontWeight="bold">
          Enterprise Package
        </Typography>
        <Typography variant="body2" color="text.secondary" mt={1}>
          Geniş ölçekli işletmeler için özel çözümler. Detaylı destek ve özel özellikler içerir.
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'center', mt: 2 }}>
        <Button variant="contained" color="primary" onClick={() => navigate('/feedback?type=support')} fullWidth>
          Bizimle İletişime Geçin
        </Button>
      </CardActions>
    </Card>
  );
};

export default EnterpriseCard;
