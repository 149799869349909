import { fetchWithAuth } from '../api/fetchWrapper';

export const fetchProducts = async () => {
  try {
    const response = await fetchWithAuth('/products', { method: 'GET' });

    if (!response.ok) {
      throw new Error('Ürünler alınamadı');
    }

    const data = await response.json();

    // Aylık ve yıllık paketler için ayrım
    const monthlyPackages = data.bundles
      .filter((bundle) =>
        bundle.prices.some((price) => price.paymentPeriod === 'monthly')
      )
      .map((bundle) => ({
        ...bundle,
        prices: bundle.prices.find(
          (price) => price.paymentPeriod === 'monthly' && price.currencyType === 'try'
        ),
      }));

    const yearlyPackages = data.bundles
      .filter((bundle) =>
        bundle.prices.some((price) => price.paymentPeriod === 'yearly')
      )
      .map((bundle) => ({
        ...bundle,
        prices: bundle.prices.find(
          (price) => price.paymentPeriod === 'yearly' && price.currencyType === 'try'
        ),
      }));

    // Tek seferlik paketler
    const oneTimePackages = data.products.map((product) => ({
      ...product,
      prices: product.prices.find((price) => price.currencyType === 'try'),
    }));

    return { monthlyPackages, yearlyPackages, oneTimePackages };
  } catch (error) {
    console.error('Ürünler alınırken hata oluştu:', error);
    throw error;
  }
};
