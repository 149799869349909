import { fetchWithAuth } from './fetchWrapper'; // fetchWithAuth burada kullanılacak

// Ratings verilerini almak için API çağrısı
export const fetchRatings = async () => {
    const url = '/profile/business/rating_details/'; // Base URL fetchWrapper tarafından eklenecek

    try {
        const response = await fetchWithAuth(url, { method: 'GET' });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        return {
            overallRating: data.latest_rating ?? localStorage.getItem("latest_rating"), // Null ise 0
            oneMonthGrowth: data.one_month_change ?? 'Bilgi yok', // Null ise 'Bilgi yok'
            sixMonthGrowth: data.six_months_change ?? 'Bilgi yok', // Null ise 'Bilgi yok'
            totalGrowth: data.total_change ?? 'Bilgi yok', // Null ise 'Bilgi yok'
        };
    } catch (error) {
        console.error('fetchRatings API çağrısı başarısız oldu:', error);
        return {
            overallRating: 0,
            oneMonthGrowth: 0,
            sixMonthGrowth: 0,
            totalGrowth: 0,
        }; // Hata durumunda varsayılan değerler döndürülür
    }
};

// Scan istatistiklerini almak için API çağrısı
export const fetchScanStats = async () => {
    const url = '/cards/trends';

    try {
        const response = await fetchWithAuth(url, { method: 'GET' });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        return {
            total_unique_client_count: data.total_unique_client_count, // API'den gelen toplam unique IP sayısı
            this_month_unique_client_count: data.this_month_unique_client_count, // Bu ayki unique IP sayısı
            last_month_unique_client_count: data.last_month_unique_client_count, // Geçen ayki unique IP sayısı
            change: data.change, // Değişim oranı
        };
    } catch (error) {
        console.error('fetchScanStats API çağrısı başarısız oldu:', error);
        return {
            total_unique_client_count: 0,
            this_month_unique_client_count: 0,
            last_month_unique_client_count: 0,
            change: 0,
        }; // Hata durumunda varsayılan değerler döndürülür
    }
};

// Acil önlemleri almak için API çağrısı
export const fetchUrgentIssues = async () => {
    // Mock data olarak acil önlemleri dönüyor
    return new Promise((resolve) => {
        resolve({
            urgentIssues: [
                "Şu anda işletmeniz için acil önlemler bulunmamaktadır. İşletmenizle ilgili yorumlar toplandıkça ve raporlar oluşturuldukça acil önlemleri buradan görüntüleyebilirsiniz. Daha fazla yorum almanız ve bu yorumların objektifliği, işletmenizin güçlü ve zayıf yönlerini daha iyi anlamamıza yardımcı olacaktır.",
            ],
        });
    });
};
