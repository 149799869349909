import { fetchWithAuth } from '../api/fetchWrapper'; // Merkezi fetch fonksiyonu

export interface Settings {
  tonality: string;
  signature: string | null;
}

const SETTINGS_ENDPOINT = '/review/review_settings/';

// Mevcut ayarları API'den çekmek için işlev
export const fetchSettings = async (): Promise<Settings> => {
  try {
    const response = await fetchWithAuth(SETTINGS_ENDPOINT, { method: 'GET' });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Ayarlar getirilirken bir hata oluştu.');
    }

    return await response.json();
  } catch (error) {
    console.error('API Hatası (fetchSettings):', error);
    throw error;
  }
};

// Ayarları API'ye kaydetmek için işlev
export const saveSettings = async (settings: Settings): Promise<void> => {
  try {
    const response = await fetchWithAuth(SETTINGS_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(settings),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Ayarlar kaydedilirken bir hata oluştu.');
    }

    console.log('Ayarlar başarıyla kaydedildi:', settings);
  } catch (error) {
    console.error('API Hatası (saveSettings):', error);
    throw error;
  }
};
