import React from 'react';
import { Box, Container, Typography, Button, Grid, Card, CardContent, CardMedia, IconButton, Avatar, Stack } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PeopleIcon from '@mui/icons-material/People';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

function LandingPage() {
  return (
    <Container maxWidth="lg">
      {/* Hero Section */}
      <Box sx={{ textAlign: 'center', py: 6, backgroundColor: '#f5f5f5', borderRadius: 3, mb: 6 }}>
        <Typography variant="h3" fontWeight="bold" gutterBottom>
          Elevate Your Business with NFC Review Management
        </Typography>
        <Typography variant="h6" color="text.secondary" paragraph>
          Collect Google reviews effortlessly, respond instantly, and gain valuable insights.
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="center" mt={4}>
          <Button variant="contained" size="large">Get Started</Button>
          <Button variant="outlined" size="large">Learn More</Button>
        </Stack>
      </Box>

      {/* Key Features Section */}
      <Box sx={{ py: 6 }}>
        <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
          How It Works
        </Typography>
        <Grid container spacing={4} mt={2}>
          <Grid item xs={12} md={4}>
            <Card variant="outlined" sx={{ height: '100%', textAlign: 'center' }}>
              <CardContent>
                <CheckCircleOutlineIcon sx={{ fontSize: 50, color: 'primary.main' }} />
                <Typography variant="h6" fontWeight="bold" mt={2}>Collect Reviews</Typography>
                <Typography color="text.secondary">Tap the NFC card to leave a Google review.</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined" sx={{ height: '100%', textAlign: 'center' }}>
              <CardContent>
                <PeopleIcon sx={{ fontSize: 50, color: 'primary.main' }} />
                <Typography variant="h6" fontWeight="bold" mt={2}>Automate Responses</Typography>
                <Typography color="text.secondary">Instant AI-powered replies for customer feedback.</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined" sx={{ height: '100%', textAlign: 'center' }}>
              <CardContent>
                <AutoGraphIcon sx={{ fontSize: 50, color: 'primary.main' }} />
                <Typography variant="h6" fontWeight="bold" mt={2}>Analyze and Report</Typography>
                <Typography color="text.secondary">Weekly and monthly reports to track insights.</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Benefits Section */}
      <Box sx={{ py: 6, backgroundColor: '#f5f5f5', borderRadius: 3, mb: 6 }}>
        <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
          Why Choose Our Solution?
        </Typography>
        <Grid container spacing={4} mt={2}>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent>
                <PriceCheckIcon sx={{ fontSize: 50, color: 'primary.main' }} />
                <Typography variant="h6" fontWeight="bold" mt={2}>Increase Customer Engagement</Typography>
                <Typography color="text.secondary">Encourage real-time feedback at the point of service.</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent>
                <ContactSupportIcon sx={{ fontSize: 50, color: 'primary.main' }} />
                <Typography variant="h6" fontWeight="bold" mt={2}>Save Time with Automation</Typography>
                <Typography color="text.secondary">Focus on your business while our AI responds to reviews.</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent>
                <AutoGraphIcon sx={{ fontSize: 50, color: 'primary.main' }} />
                <Typography variant="h6" fontWeight="bold" mt={2}>Get Data-Driven Insights</Typography>
                <Typography color="text.secondary">Receive in-depth reports to understand customer sentiments.</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Product Demo Section */}
      <Box sx={{ textAlign: 'center', py: 6 }}>
        <Typography variant="h4" fontWeight="bold" gutterBottom>See It In Action</Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Watch how easy it is for customers to leave a review and how our dashboard empowers your business.
        </Typography>
        <Button variant="contained" size="large" sx={{ mt: 3 }}>Watch Demo</Button>
      </Box>

      {/* Pricing Section */}
      <Box sx={{ py: 6 }}>
        <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
          Pricing Plans
        </Typography>
        <Grid container spacing={4} mt={2}>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" fontWeight="bold" textAlign="center">Basic</Typography>
                <Typography variant="h4" fontWeight="bold" textAlign="center" color="primary.main">₺99/month</Typography>
                <Typography color="text.secondary" textAlign="center">For small businesses just getting started.</Typography>
                <Button variant="outlined" fullWidth sx={{ mt: 3 }}>Choose Plan</Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" fontWeight="bold" textAlign="center">Pro</Typography>
                <Typography variant="h4" fontWeight="bold" textAlign="center" color="primary.main">₺199/month</Typography>
                <Typography color="text.secondary" textAlign="center">For growing businesses needing more features.</Typography>
                <Button variant="outlined" fullWidth sx={{ mt: 3 }}>Choose Plan</Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" fontWeight="bold" textAlign="center">Enterprise</Typography>
                <Typography variant="h4" fontWeight="bold" textAlign="center" color="primary.main">Contact Us</Typography>
                <Typography color="text.secondary" textAlign="center">Custom solutions for larger enterprises.</Typography>
                <Button variant="outlined" fullWidth sx={{ mt: 3 }}>Contact Sales</Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Footer */}
      <Box sx={{ textAlign: 'center', py: 4, mt: 6, backgroundColor: '#333', color: '#fff', borderRadius: 1 }}>
        <Typography variant="body2">© 2024 FeedUp AI. All Rights Reserved.</Typography>
      </Box>
    </Container>
  );
}

export default LandingPage;
