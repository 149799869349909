import { API_BASE_URL } from '../config';

export async function fetchWithAuth(url: string, options: RequestInit = {}) {
    let token = localStorage.getItem('SEND_TOKEN_TO_EXTENSION');
    const refreshToken = localStorage.getItem('refresh_token');

    // Headers ayarla
    const headers = {
        'Content-Type': 'application/json',
        ...options.headers,
        referrerPolicy: "unsafe-url"
    };

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    const requestOptions: RequestInit = {
        ...options,
        headers,
    };

    let response = await fetch(`${API_BASE_URL}${url}`, requestOptions);

    // Eğer token süresi dolmuşsa, refresh token ile yenile
    if (response.status === 403 && refreshToken) {
        const refreshResponse = await fetch(`${API_BASE_URL}/auth/token/refresh?refresh_auth_token=${refreshToken}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            referrerPolicy: "unsafe-url"
        });

        if (refreshResponse.ok) {
            const data = await refreshResponse.json();
            token = data.id_token;

            // Yeni tokenları kaydet
            localStorage.setItem('SEND_TOKEN_TO_EXTENSION', token);
            window.postMessage({ type: 'SEND_TOKEN_TO_EXTENSION', token }, '*');
            if (data.refresh_token) {
                localStorage.setItem('refresh_token', data.refresh_token);
            }

            // Yeniden deneyim
            headers['Authorization'] = `Bearer ${token}`;
            requestOptions.headers = headers;
            response = await fetch(`${API_BASE_URL}${url}`, requestOptions);
        } else {
            console.error('Token yenileme başarısız:', refreshResponse.statusText);
            localStorage.clear(); // Tokenları temizle
            const logout_var = true;
            window.postMessage({ type: 'FEEDUP_LOGOUT' }, '*');
        }
    }

    return response;
}
