import * as React from 'react';
import Stack from '@mui/material/Stack';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import NavbarBreadcrumbs from './NavbarBreadcrumbs';
import MenuButton from './MenuButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

// Mock notifications data
const mockNotifications = [
  //{ id: 1, message: "Henüz bir bildiriminiz yok", time: "0 dakika önce" },
];

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      direction="row"
      sx={{
        display: { xs: 'none', md: 'flex' },
        width: '100%',
        alignItems: { xs: 'flex-start', md: 'center' },
        justifyContent: 'space-between',
        maxWidth: { sm: '100%', md: '1700px' },
        pt: 1.5,
      }}
      spacing={2}
    >
      <NavbarBreadcrumbs />
      <Stack direction="row" sx={{ gap: 1 }}>
        <MenuButton onClick={handleClick} aria-label="Open notifications">
          <Badge badgeContent={mockNotifications.length} color="error">
            <NotificationsRoundedIcon />
          </Badge>
        </MenuButton>
        
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: 300,
              maxHeight: 300,
              mt: 1,
            },
          }}
        >
          <Typography variant="h6" sx={{ p: 2 }}>Bildirimler</Typography>
          <Divider />
          {mockNotifications.length > 0 ? (
            mockNotifications.map((notification) => (
              <MenuItem key={notification.id} onClick={handleClose}>
                <Stack direction="column" spacing={0.5}>
                  <Typography variant="body2">{notification.message}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {notification.time}
                  </Typography>
                </Stack>
              </MenuItem>
            ))
          ) : (
            <MenuItem onClick={handleClose}>
              <Typography variant="body2" color="text.secondary">
                Henüz bir bildiriminiz yok
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </Stack>
    </Stack>
  );
}
