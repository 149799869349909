// ProtectedRoute.tsx
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const token = localStorage.getItem('SEND_TOKEN_TO_EXTENSION');

  if (!token) {
    // Redirect to login if token is missing
    return <Navigate to="/login" />;
  }

  return <>{children}</>; // Render the children if authenticated
};

export default ProtectedRoute;
