import React from 'react';
import { Stack, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import getSignUpTheme from '../../theme/getSignUpTheme';
import SignUpForm from './SignUpForm';

const SignUpContainer: React.FC = () => {
  const theme = createTheme(getSignUpTheme('light'));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Stack
        sx={{
          height: '100vh', // Tüm ekranı kapsar
          overflowY: 'auto', // Dikey kaydırma
          alignItems: 'center',
          justifyContent: 'center',
          padding: 4,
        }}
      >
        <SignUpForm />
      </Stack>
    </ThemeProvider>
  );
};

export default SignUpContainer;
