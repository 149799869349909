import * as React from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Pagination,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { Report, fetchReports } from '../api/reportApi';

export default function Analytics() {
  const [reportType, setReportType] = React.useState<'weekly' | 'monthly'>('weekly');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [reports, setReports] = React.useState<Report[]>([]);
  const [totalReports, setTotalReports] = React.useState(0);
  const [reportsPerPage, setReportsPerPage] = React.useState(10);
  const location = useLocation();
  const [loading, setLoading] = React.useState(true); // Yüklenme durumunu takip etmek için state

  React.useEffect(() => {
    if (location.state?.page) {
      setCurrentPage(location.state.page);
    }
    if (location.state?.reportType) {
      setReportType(location.state.reportType);
    }
  }, [location.state]);

  React.useEffect(() => {
    const handleResize = () => {
      const screenHeight = window.innerHeight;
      const perPage = screenHeight < 300 ? 3 : screenHeight < 700 ? 6 : screenHeight < 800 ? 8 : 10;
      setReportsPerPage(perPage);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Veriyi API'den çekme işlemi
  const loadReports = async (page: number, type: 'weekly' | 'monthly') => {
    setLoading(true); // API çağrısı başlamadan önce yüklenme durumunu true yap
    try {
      const { reports: fetchedReports, totalCount } = await fetchReports(page, reportsPerPage, type);
      setReports(fetchedReports);
      setTotalReports(totalCount);
    } catch (error) {
      console.error('Raporları yüklerken hata oluştu:', error);
    } finally {
      setLoading(false); // API çağrısı tamamlandıktan sonra yüklenme durumunu false yap
    }
  };
  

  // İlk yüklemede ve sayfa/rapor türü değiştiğinde veri yükleme
  React.useEffect(() => {
    loadReports(currentPage, reportType);
  }, [currentPage, reportType, reportsPerPage]);

  const handleReportTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newReportType: 'weekly' | 'monthly' | null,
  ) => {
    if (newReportType !== null) {
      setReportType(newReportType);
      setCurrentPage(1);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const totalPages = Math.ceil(totalReports / reportsPerPage);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 2, sm: 0 },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            fontSize: { xs: '1.5rem', sm: '2rem' },
            textAlign: { xs: 'center', sm: 'left' },
          }}
        >
          Analitik Raporlar
        </Typography>
        <ToggleButtonGroup
          value={reportType}
          exclusive
          onChange={handleReportTypeChange}
          aria-label="Rapor Türü"
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'flex-end' },
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          <ToggleButton 
            value="weekly" 
            aria-label="Haftalık"
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'flex-end' },
              width: { xs: '100%', sm: 'auto' },
            }}
          >
            Haftalık
          </ToggleButton>
          <ToggleButton 
            value="monthly" 
            aria-label="Aylık"
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'flex-end' },
              width: { xs: '100%', sm: 'auto' },
            }}
          >
            Aylık
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <List>
        {loading ? (
          <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
            <ListItemText
              primary="Raporlar yükleniyor..."
              sx={{ textAlign: 'center' }}
            />
          </ListItem>
        ) : reports.length === 0 ? (
          <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
            <ListItemText
              primary={
                reportType === 'weekly'
                  ? 'Haftalık raporunuz bulunmamaktadır.'
                  : 'Aylık raporunuz bulunmamaktadır.'
              }
              sx={{ textAlign: 'center' }}
            />
          </ListItem>
        ) : (
          reports.map((report) => (
            <Box key={report.id}>
              <ListItem disablePadding>
                <ListItemButton
                  component={Link}
                  to={`/reportdetail/${report.id}`}
                  state={{ fromPage: currentPage, reportType }}
                >
                  <ListItemText primary={report.title} secondary={report.date} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </Box>
          ))
        )}
      </List>




      <Box display="flex" justifyContent="center" mt={4}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Container>
  );
}
