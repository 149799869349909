import * as React from 'react';
import { Container, Typography, Box, Stack, Avatar, Grid, Paper } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import InsightsIcon from '@mui/icons-material/Insights';
import PeopleIcon from '@mui/icons-material/People';
import { useTheme } from '@mui/material/styles';

export default function About() {
  const theme = useTheme();

  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Box textAlign="center" mb={4}>
        <Typography variant="h4" component="h1" fontWeight="bold" gutterBottom>
          Hakkımızda
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          feedup.ai, işletme sahiplerine müşteri geri bildirimlerini yönetme ve yanıt verme konusunda yenilikçi çözümler sunan bir girişimdir.
        </Typography>
      </Box>

      <Paper elevation={3} sx={{ p: 3, mb: 4, backgroundColor: theme.palette.background.default }}>
        <Stack direction="column" alignItems="center" spacing={2}>
          <Avatar sx={{ bgcolor: theme.palette.primary.main, width: 60, height: 60 }}>
            <BusinessIcon fontSize="large" />
          </Avatar>
          <Typography variant="h5" fontWeight="medium">
            Müşteri Geri Bildirimlerini Artırın
          </Typography>
          <Typography variant="body1" color="text.secondary" textAlign="center">
            NFC teknolojisi ile müşterilerinizi hızlıca Google yorum bırakma sayfasına yönlendiriyor, yorum sayısını artırıyoruz.
          </Typography>
        </Stack>
      </Paper>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Stack direction="column" alignItems="center" spacing={2}>
              <Avatar sx={{ bgcolor: theme.palette.success.main, width: 50, height: 50 }}>
                <InsightsIcon />
              </Avatar>
              <Typography variant="h6" fontWeight="medium">
                Otomatik Yanıtlar
              </Typography>
              <Typography variant="body2" color="text.secondary" textAlign="center">
                Yorumlara işletme tarzınıza uygun yanıtlar üretiyoruz. Böylece müşterilerle sürekli iletişim kurabilirsiniz.
              </Typography>
            </Stack>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Stack direction="column" alignItems="center" spacing={2}>
              <Avatar sx={{ bgcolor: theme.palette.info.main, width: 50, height: 50 }}>
                <PeopleIcon />
              </Avatar>
              <Typography variant="h6" fontWeight="medium">
                Haftalık & Aylık Raporlar
              </Typography>
              <Typography variant="body2" color="text.secondary" textAlign="center">
                Müşteri yorumlarını analiz ediyor ve haftalık ile aylık performans raporları sunuyoruz.
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      </Grid>

      <Box mt={6} textAlign="center">
        <Typography variant="h5" component="h2" fontWeight="bold" gutterBottom>
          Ekibimiz
        </Typography>
        <Typography variant="body1" color="text.secondary" mb={2}>
          Ekibimiz, Microsoft, Silikon Vadisi ve dünya çapında tanınmış lider kuruluşlarda edinilen deneyimlerle desteklenmektedir.
        </Typography>
      </Box>
    </Container>
  );
}
