import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiToolbar from '@mui/material/Toolbar';
import { tabsClasses } from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import SideMenuMobile from './SideMenuMobile';
import MenuButton from './MenuButton';
import { useNavigate } from 'react-router-dom';
import logo from './logo.png'; 

const Toolbar = styled(MuiToolbar)({
  width: '100%',
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  gap: '12px',
  flexShrink: 0,
  [`& ${tabsClasses.flexContainer}`]: {
    gap: '8px',
    p: '8px',
    pb: 0,
  },
});

export default function AppNavbar() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate(); // useNavigate kancasını tanımlayın

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleSelectPage = (page: string) => {
    console.log(`Navigating to ${page}`);
    setOpen(false); // Menü kapanacak
    navigate(`/${page.toLowerCase()}`); // Yönlendirme işlemi
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        display: { xs: 'auto', md: 'none' },
        boxShadow: 0,
        bgcolor: 'background.paper',
        backgroundImage: 'none',
        borderBottom: '1px solid',
        borderColor: 'divider',
        top: 'var(--template-frame-height, 0px)',
      }}
    >
      <Toolbar variant="regular">
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
          }}
        >
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
            <CustomIcon />
            <Typography variant="h4" component="h1" sx={{ color: 'text.primary' }}>
              FEEDUP AI
            </Typography>
          </Stack>
          <MenuButton aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuRoundedIcon />
          </MenuButton>
          <SideMenuMobile open={open} toggleDrawer={toggleDrawer} onSelectPage={handleSelectPage} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

{/*export function CustomIcon() {
  const imageUrl = 'https://feedup.ai/public/feedup2-1500h.png'; // URL üzerinden görsel

  return (
    <Box
      component="img"
      src={imageUrl}
      alt="Logo"
      sx={{
        width: '2rem', // Görsel boyutu büyütüldü
        height: '2rem',
        borderRadius: '999px',
        border: '1px solid',
        borderColor: 'hsl(210, 100%, 55%)',
        boxShadow: 'inset 0 2px 5px rgba(255, 255, 255, 0.3)',
        objectFit: 'cover',
      }}
    />
  );
}*/}
export function CustomIcon() {
  return (
    <Box
      component="img"
      src={logo}
      alt="Logo"
      sx={{
        width: '2rem',
        height: '2rem',
        borderRadius: '999px',
        border: '1px solid',
        borderColor: 'hsl(210, 100%, 55%)',
        boxShadow: 'inset 0 2px 5px rgba(255, 255, 255, 0.3)',
        objectFit: 'cover',
      }}
    />
  );
}
