import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from './components/AppNavbar';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import AppTheme from './theme/AppTheme';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from './theme/customizations';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function Layout(props: { disableCustomTheme?: boolean }) {
  const navigate = useNavigate();  // useNavigate eklendi

  const handleSelectPage = (page: string) => {
    const path = `/${page.toLowerCase()}`;  // Sayfa adını URL'ye dönüştürme
    navigate(path); // Yönlendirme
  };

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu onSelectPage={handleSelectPage} /> {/* handleSelectPage fonksiyonunu iletin */}
        <AppNavbar />
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <Outlet /> {/* Bu, yönlendirme yapılan sayfanın içeriğini gösterecek */}
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
