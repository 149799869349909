import { fetchWithAuth } from '../api/fetchWrapper'; // Merkezi fetch fonksiyonunuz

export interface CardData {
  card_name: string;
  card_id: string;
  unique_client_count: number;
  nfc_unique_client_count: number;
  qr_unique_client_count: number;
  both_qr_and_nfc_unique_client_count: number;
}

export interface ApiResponse {
  total_unique_client_count: number;
  total_nfc_unique_client_count: number;
  total_qr_unique_client_count: number;
  average_unique_client_count_per_card: number;
  total_both_unique_client_count: number;
  cards: CardData[];
}

export async function fetchData(startDate?: string, endDate?: string): Promise<ApiResponse> {
  const url = '/cards/'; // API Base URL fetchWithAuth içinde tanımlı

  // Query parametrelerini ekle
  const queryParams = new URLSearchParams();
  if (startDate) queryParams.append('start_date', startDate);
  if (endDate) queryParams.append('end_date', endDate);

  const fullUrl = `${url}?${queryParams.toString()}`;

  try {
    const response = await fetchWithAuth(fullUrl, { method: 'GET' });

    if (!response.ok) {
      throw new Error(`API çağrısı başarısız oldu: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching card data:', error);
    throw error;
  }
}

export async function updateCardName(card_id: string, new_card_name: string): Promise<void> {
  const url = `/cards/${card_id}/update-name`; // API endpoint

  try {
    const response = await fetchWithAuth(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ new_name: new_card_name }),
    });

    if (!response.ok) {
      throw new Error(`Kart adı güncellenemedi: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error updating card name:', error);
    throw error;
  }
}