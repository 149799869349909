import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  TextField,
  Typography,
  Stack,
  Card as MuiCard,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FormLabel from '@mui/material/FormLabel';

import LocationSearch from './LocationSearch';
import { registerUser } from '../../api/signUpApi';
import { useNavigate } from 'react-router-dom';
import { CustomIcon } from '../CustomIcons';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  overflowY: 'auto',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
}));

const ScrollableBox = styled(Box)({
  maxHeight: '900px',
});

const SignUpForm: React.FC = () => {
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
  const [locationQuery, setLocationQuery] = useState('');
  const [selectedLocationDetails, setSelectedLocationDetails] = useState<any | null>(null);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [distanceSaleChecked, setDistanceSaleChecked] = useState(false);
  const [distanceSaleError, setDistanceSaleError] = useState(false);
  const navigate = useNavigate();

  const validateInputs = () => {
    const email = document.getElementById('email') as HTMLInputElement;
    const password = document.getElementById('password') as HTMLInputElement;
    const confirmPassword = document.getElementById('confirm-password') as HTMLInputElement;
    const phone = document.getElementById('phone') as HTMLInputElement;

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Lütfen geçerli bir eposta hesabı giriniz.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('En az 6 karakter uzunluğunda olmalı.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if (!confirmPassword.value || confirmPassword.value !== password.value) {
      setConfirmPasswordError(true);
      setConfirmPasswordErrorMessage('Şifreler eşleşmiyor.');
      isValid = false;
    } else {
      setConfirmPasswordError(false);
      setConfirmPasswordErrorMessage('');
    }

    if (!phone.value || phone.value.length < 10) {
      setPhoneError(true);
      setPhoneErrorMessage('Geçerli bir telefon numarası giriniz.');
      isValid = false;
    } else {
      setPhoneError(false);
      setPhoneErrorMessage('');
    }

    if (!privacyChecked) {
      setPrivacyError(true);
      isValid = false;
    } else {
      setPrivacyError(false);
    }

    if (!distanceSaleChecked) {
      setDistanceSaleError(true);
      isValid = false;
    } else {
      setDistanceSaleError(false);
    }

    return isValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateInputs()) return;

    const email = (document.getElementById('email') as HTMLInputElement).value;
    const password = (document.getElementById('password') as HTMLInputElement).value;
    const phone = (document.getElementById('phone') as HTMLInputElement).value;

    const registrationData = {
      business_name: selectedLocationDetails?.business_name,
      business_type: selectedLocationDetails?.business_types,
      business_id: selectedLocationDetails?.place_id,
      phone,
      address: selectedLocationDetails?.business_address,
      email,
      password,
      prompt: "",
      google_maps_url: `https://www.google.com/maps/place/?q=place_id:${selectedLocationDetails?.place_id}`
    };

    try {
      await registerUser(registrationData);
      navigate('/login');
    } catch (error) {
      console.error("Kayıt başarısız:", error);
    }
  };

  return (
    <Card>
      <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
        <CustomIcon />
        <Typography variant="h3" component="h3" sx={{ color: 'text.primary', fontSize: '1.5rem' }}>
          FEEDUP AI
        </Typography>
      </Stack>
      <Typography component="h1" variant="h3" sx={{ textAlign: 'center', fontSize: '1.2rem' }}>
        Kayıt Ol
      </Typography>
      <ScrollableBox>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <FormControl>
            <FormLabel htmlFor="email">Eposta</FormLabel>
            <TextField
              required
              fullWidth
              id="email"
              placeholder="ahmet.demir@email.com"
              name="email"
              autoComplete="email"
              variant="outlined"
              error={emailError}
              helperText={emailErrorMessage}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Şifre</FormLabel>
            <TextField
              required
              fullWidth
              name="password"
              placeholder="••••••"
              type="password"
              id="password"
              autoComplete="new-password"
              variant="outlined"
              error={passwordError}
              helperText={passwordErrorMessage}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="confirm-password">Şifreyi Tekrar Girin</FormLabel>
            <TextField
              required
              fullWidth
              name="confirm-password"
              placeholder="••••••"
              type="password"
              id="confirm-password"
              autoComplete="new-password"
              variant="outlined"
              error={confirmPasswordError}
              helperText={confirmPasswordErrorMessage}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="phone">Telefon</FormLabel>
            <TextField
              required
              fullWidth
              id="phone"
              placeholder="+90 555 123 45 67"
              type="tel"
              error={phoneError}
              helperText={phoneErrorMessage}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="location">İşletmenizi Google'da Bulun</FormLabel>
            <LocationSearch
              locationQuery={locationQuery}
              setLocationQuery={setLocationQuery}
              setSelectedLocationDetails={setSelectedLocationDetails}
            />
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={privacyChecked}
                onChange={(e) => setPrivacyChecked(e.target.checked)}
              />
            }
            label={
              <span>
                <Link href="https://feedup.ai/privacypolicy" target="_blank">
                  Gizlilik Sözleşmesi
                </Link>
                'ni kabul ediyorum
              </span>
            }
          />
          {privacyError && (
            <Typography variant="caption" color="error" sx={{ ml: 2 }}>
              Zorunlu alan!
            </Typography>
          )}
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={distanceSaleChecked}
                onChange={(e) => setDistanceSaleChecked(e.target.checked)}
              />
            }
            label={
              <span>
                <Link href="https://feedup.ai/termsofservice" target="_blank">
                  Şartlar ve Koşullar
                </Link>
                'ı kabul ediyorum
              </span>
            }
          />
          {distanceSaleError && (
            <Typography variant="caption" color="error" sx={{ ml: 2 }}>
              Zorunlu alan!
            </Typography>
          )}
          <Button type="submit" fullWidth variant="contained">
            Kayıt Ol!
          </Button>
          <Typography sx={{ textAlign: 'center' }}>
            Zaten hesabınız var mı?{' '}
            <span>
              <Link href="/login" variant="body2" sx={{ alignSelf: 'center' }}>
                Giriş yapın
              </Link>
            </span>
          </Typography>
        </Box>
      </ScrollableBox>
    </Card>
  );
};

export default SignUpForm;
