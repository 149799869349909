import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Tabs, Tab, Box, Badge, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SubscriptionCard from '../components/productCardViews/SubscriptionCard';
import PackageCard from '../components/productCardViews/PackageCard';
import EnterpriseCard from '../components/productCardViews/EnterpriseCard';
import { Link } from 'react-router-dom';
import { fetchProducts } from '../api/productApi';

export default function Sales() {
  const [products, setProducts] = useState({
    monthlyPackages: [],
    yearlyPackages: [],
    oneTimePackages: [],
  });

  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const [cartUpdateTrigger, setCartUpdateTrigger] = useState(0); // Tetikleyici ekledik
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const fetchedProducts = await fetchProducts();
        setProducts(fetchedProducts);
      } catch (error) {
        console.error('Ürünler alınırken hata oluştu:', error);
      }
    };
    loadProducts();
  }, []);

  useEffect(() => {
    // `cartUpdateTrigger` tetiklendiğinde `localStorage`'dan sepeti yeniden oku
    const savedCart = localStorage.getItem('cart');
    setCart(savedCart ? JSON.parse(savedCart) : []);
  }, [cartUpdateTrigger]);

  const clearCart = () => {
    localStorage.setItem('cart', JSON.stringify([]));
    setCartUpdateTrigger((prev) => prev + 1); // Tetikleyici artır
  };

  const handleTabChange = (event, newValue) => setSelectedTab(newValue);

  const cartItemCount = cart.reduce((total, item) => total + (item.quantity || 0), 0);

  const groupedSubscriptions = Object.values(
    products.monthlyPackages.concat(products.yearlyPackages).reduce((groups, product) => {
      if (!groups[product.name]) groups[product.name] = [];
      groups[product.name].push(product);
      return groups;
    }, {})
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, px: { xs: 2, sm: 4 } }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" fontWeight="bold">Satış Alanı</Typography>
        <IconButton component={Link} to="/cart" color="primary">
          <Badge badgeContent={cartItemCount} color="secondary">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      </Box>

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        centered
        variant="fullWidth"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab label="Abonelikler" />
        <Tab label="Paketler" />
      </Tabs>

      {selectedTab === 0 && (
        <Grid container spacing={2} mt={2}>
          {groupedSubscriptions.map((productGroup, index) => (
            <Grid item xs={12} sm={6} md={4} key={`${productGroup[0].name}-${index}`}>
              <SubscriptionCard productGroup={productGroup} setCartUpdateTrigger={setCartUpdateTrigger} />
            </Grid>
          ))}

          <Grid item xs={12} sm={6} md={4}>
            <EnterpriseCard />
          </Grid>
        </Grid>
      )}

      {selectedTab === 1 && (
        <Grid container spacing={2} mt={2}>
          {products.oneTimePackages.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.sku}>
              <PackageCard product={product} setCartUpdateTrigger={setCartUpdateTrigger} />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
}
