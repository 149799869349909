import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useState } from 'react';
import { API_BASE_URL } from '../config';

interface ForgotPasswordProps {
  open: boolean;
  handleClose: () => void;
}

export default function ForgotPassword({ open, handleClose }: ForgotPasswordProps) {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleForgotPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/auth/password/forgot?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(errorData.detail || 'Şifre sıfırlama isteği başarısız.');
        return;
      }

      setSuccessMessage('Şifre sıfırlama bağlantısı e-posta adresinize gönderildi.');
      setEmail(''); // Reset email field after success
    } catch (error) {
      setErrorMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
      console.error('Şifre sıfırlama API hatası:', error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleForgotPassword,
      }}
    >
      <DialogTitle>Şifreyi Sıfırla</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        {successMessage ? (
          <DialogContentText color="primary" variant="body2">
            {successMessage}
          </DialogContentText>
        ) : (
          <>
            <DialogContentText>
              Hesabınızın e-posta adresini girin, size şifrenizi sıfırlamanız için bir bağlantı göndereceğiz.
            </DialogContentText>
            {errorMessage && (
              <DialogContentText color="error" variant="body2">
                {errorMessage}
              </DialogContentText>
            )}
            <OutlinedInput
              autoFocus
              required
              margin="dense"
              id="email"
              name="email"
              label="E-posta adresi"
              placeholder="E-posta adresi"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        {successMessage ? (
          <Button onClick={handleClose} variant="contained">
            Kapat
          </Button>
        ) : (
          <>
            <Button onClick={handleClose}>İptal</Button>
            <Button variant="contained" type="submit">
              Devam
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
