import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { addToCart, removeOneItem, removeFromCart, getCartFromLocalStorage, clearCart } from '../helpers/localStorageCart';

export default function Cart() {
  const [cart, setCart] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Sepet verisini localStorage'dan yükleme
  useEffect(() => {
    setCart(getCartFromLocalStorage());
  }, []);

  // Toplam fiyat hesaplama
  const calculateTotalPrice = () => {
    return cart
      .reduce((total, item) => {
        const price = Array.isArray(item.prices)
          ? item.prices.find((p) => p.currencyType === 'try')?.value || 0
          : item.prices.value || 0;
        return total + price * (item.quantity || 1);
      }, 0)
      .toFixed(2);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" fontWeight="bold" mb={3}>
        Sepetiniz
      </Typography>

      {cart.length === 0 ? (
        <Typography variant="body1" color="textSecondary" align="center" mt={4}>
          Sepetiniz boş. Ürün eklemek için geri dönün!
        </Typography>
      ) : (
        <>
          <Box display="flex" justifyContent="flex-start" width="100%">
            <List sx={{ width: '100%' }}>
              {cart.map((item) => (
                <ListItem
                  key={item.sku}
                  divider
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <ListItemText
                    primary={`${item.name} ${
                      item.prices.paymentPeriod === 'monthly' ? 'Aylık' : item.prices.paymentPeriod === 'yearly' ? 'Yıllık' : ''
                    }`}
                    secondary={`Adet: ${item.quantity} x Birim Fiyat: ₺${
                      Array.isArray(item.prices)
                        ? item.prices.find((p) => p.currencyType === 'try')?.value || '0.00'
                        : item.prices?.value || '0.00'
                    }`}
                  />

                  <Box display="flex" alignItems="center" gap={1}>
                    {item.prices.paymentPeriod === 'onetime' && (
                      <>
                        <IconButton
                          size="small"
                          onClick={() => setCart(removeOneItem(item.sku))}
                          color="secondary"
                        >
                          <RemoveIcon fontSize="small" />
                        </IconButton>
                        <Typography variant="body2">{item.quantity || 0}</Typography>
                        <IconButton
                          size="small"
                          onClick={() => setCart(addToCart(item))}
                          color="primary"
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </>
                    )}
                    <IconButton
                      size="small"
                      onClick={() => setCart(removeFromCart(item.sku))}
                      color="error"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>

          <Box textAlign="center" mt={4}>
            <Typography variant="h6" fontWeight="bold" mb={2}>
              Toplam Fiyat: ₺{calculateTotalPrice()}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleOpenDialog} sx={{ mr: 2 }}>
              Ödemeye Git
            </Button>
            <Button variant="outlined" color="error" onClick={() => setCart(clearCart())}>
              Sepeti Boşalt
            </Button>
          </Box>

          {/* Pop-up Dialog */}
          <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
            <DialogTitle>İyzico Entegrasyonu Yakında!</DialogTitle>
            <DialogContent>
              <Typography variant="body2" color="textSecondary" mb={2}>
                Sepetinizdeki ürünler:
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Ürün Adı</TableCell>
                      <TableCell align="right">SKU</TableCell>
                      <TableCell align="right">Offer ID</TableCell>
                      <TableCell align="right">Adet</TableCell>
                      <TableCell align="right">Fiyat</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cart.map((item) => (
                      <TableRow key={item.sku}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell align="right">{item.sku}</TableCell>
                        <TableCell align="right">{item.prices.offerId || 'Bilinmiyor'}</TableCell>
                        <TableCell align="right">{item.quantity}</TableCell>
                        <TableCell align="right">
                          ₺
                          {Array.isArray(item.prices)
                            ? item.prices.find((p) => p.currencyType === 'try')?.value || '0.00'
                            : item.prices?.value || '0.00'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Kapat
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  );
}
