import * as React from 'react';
import { Box, Typography } from '@mui/material';

export default function TestPage() {
  return (
    <Box sx={{ p: 2, width: '100%', maxWidth: '100vw' }}>
      <Typography variant="h4" gutterBottom>Test Sayfası</Typography>
      {/* Test edilen bileşen */}
      <Box sx={{ width: '100%' }}>
      </Box>
    </Box>
  );
}
