import * as React from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  MenuItem,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { submitFeedback } from '../api/feedbackApi';

const FormContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: '1px solid rgba(0, 0, 0, 0.1)',
  maxWidth: '600px',
  margin: 'auto',
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

export default function Feedback() {
  const location = useLocation();
  const fromEnterprise = location.search.includes('type=support');
  const [feedbackType, setFeedbackType] = React.useState(fromEnterprise ? 'support' : 'suggestion');
  const [feedbackText, setFeedbackText] = React.useState('');
  const [surveyResponse, setSurveyResponse] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<'success' | 'error'>('success');

  const handleSubmit = async () => {
    if (!feedbackText && !surveyResponse) {
      setSnackbarMessage('Lütfen geri bildiriminizi veya anket cevabınızı giriniz.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      const numericSurveyResponse = surveyResponse
        ? { "Çok iyi": 5, "İyi": 4, "Orta": 3, "Kötü": 2, "Çok Kötü": 1 }[surveyResponse] || 0
        : undefined;

      await submitFeedback(feedbackType, feedbackText, numericSurveyResponse);
      setSnackbarMessage('Geri bildiriminiz başarıyla gönderildi! Teşekkür ederiz.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      // Form alanlarını sıfırla, ancak "Destek" türü varsa onu koru
      setFeedbackText('');
      setSurveyResponse('');
      if (!fromEnterprise) setFeedbackType('suggestion');
    } catch (error) {
      setSnackbarMessage('Geri bildirim gönderilirken bir hata oluştu. Lütfen tekrar deneyin.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <FormContainer maxWidth="md">
      <Typography variant="h4" sx={{ color: 'text.primary', fontWeight: 'bold', mb: 2 }}>
        Geri Bildirim
      </Typography>

      <TextField
        select
        label="Geri Bildirim Türü"
        value={feedbackType}
        onChange={(e) => setFeedbackType(e.target.value)}
        fullWidth
        disabled={fromEnterprise} // Enterprise'dan gelenlerde türü değiştirmeye izin verme
      >
        <MenuItem value="suggestion">Öneri</MenuItem>
        <MenuItem value="complaint">Şikayet</MenuItem>
        <MenuItem value="other">Diğer</MenuItem>
        <MenuItem value="support">Destek</MenuItem>
      </TextField>

      <TextField
        label="Geri Bildiriminiz"
        variant="standard"
        fullWidth
        multiline
        rows={4}
        value={feedbackText}
        onChange={(e) => setFeedbackText(e.target.value)}
      />

      {/* "Destek" türünde ise anketi gizle */}
      {feedbackType !== 'support' && (
        <>
          <Typography variant="h6" sx={{ color: 'text.secondary', fontWeight: 'medium', mt: 3 }}>
            Anket
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
            FeedUp Dashboard'u nasıl değerlendirirsiniz?
          </Typography>
          <RadioGroup
            value={surveyResponse}
            onChange={(e) => setSurveyResponse(e.target.value)}
          >
            <FormControlLabel value="Çok iyi" control={<Radio />} label="Çok iyi" />
            <FormControlLabel value="İyi" control={<Radio />} label="İyi" />
            <FormControlLabel value="Orta" control={<Radio />} label="Orta" />
            <FormControlLabel value="Kötü" control={<Radio />} label="Kötü" />
            <FormControlLabel value="Çok Kötü" control={<Radio />} label="Çok Kötü" />
          </RadioGroup>
        </>
      )}

      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={handleSubmit}>
          Gönder
        </Button>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Box sx={{ mt: 3, textAlign: 'center' }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          İletişim: info@feedup.ai
        </Typography>
      </Box>
    </FormContainer>
  );
}
