import * as React from 'react';
import { Container, Typography, IconButton, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ReportDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const reportId = location.pathname.split('/').pop();

  const handleBackClick = () => {
    const fromPage = location.state?.fromPage || 1;
    const reportType = location.state?.reportType || 'weekly';
    navigate('/analytics', { state: { page: fromPage, reportType } });
  };

  const pdfUrl = 'https://ontheline.trincoll.edu/images/bookdown/sample-local-pdf.pdf#toolbar=1'; // Test için online PDF

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box display="flex" alignItems="center" mb={3}>
        <IconButton onClick={handleBackClick} color="primary" sx={{ mr: 1 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" fontWeight="bold">
          {reportId} - Rapor Detayı
        </Typography>
      </Box>

      <Box sx={{ height: '600px', border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '4px', mb: 3 }}>
        <iframe
          src={pdfUrl}
          width="100%"
          height="100%"
          style={{ border: 'none' }}
          title="PDF Viewer"
        />
      </Box>
    </Container>
  );
}
