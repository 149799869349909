import * as React from 'react';
import { useNavigate } from 'react-router-dom'; 
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import PersonIcon from '@mui/icons-material/Person2';
import MenuButton from './MenuButton';
import MenuContent from './MenuContent';
import CardAlert from './CardAlert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

interface SideMenuMobileProps {
  open: boolean;
  toggleDrawer: (newOpen: boolean) => () => void;
  onSelectPage: (page: string) => void;
}

export default function SideMenuMobile({
  open,
  toggleDrawer,
  onSelectPage,
}: SideMenuMobileProps) {
  const [notificationsOpen, setNotificationsOpen] = React.useState(false);
  const navigate = useNavigate();
  
  // Local storage'dan name alınıyor, yoksa varsayılan olarak 'Kaktüs Cihangir' kullanılıyor.
  const name = localStorage.getItem('business_name') || 'Feedup AI';

  const handleLogout = () => {
    localStorage.removeItem('SEND_TOKEN_TO_EXTENSION'); // Token silinir
    localStorage.clear();
    window.postMessage({ type: 'FEEDUP_LOGOUT' }, '*');
    toggleDrawer(false)();
    navigate('/');
  };

  const handleProfile = () => {
    toggleDrawer(false)();
    navigate('/profile');
  };

  // Mock bildirim verisi
  const mockNotifications = [
    //'Henüz bir bildiriminiz yok',
  ];

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          backgroundImage: 'none',
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Stack
        sx={{
          maxWidth: '70dvw',
          height: '100%',
        }}
      >
        <Stack direction="row" sx={{ p: 2, pb: 0, gap: 1 }}>
          <Stack
            direction="row"
            sx={{ gap: 1, alignItems: 'center', flexGrow: 1, p: 1 }}
          >
            <Avatar
              sizes="small"
              alt={name} // Local storage'dan gelen name kullanılıyor
              src="https://toppng.com/uploads/preview/app-icon-set-login-icon-comments-avatar-icon-11553436380yill0nchdm.png"
              sx={{ width: 24, height: 24 }}
            />
            <Typography component="p" variant="h6">
              {name} {/* Local storage'dan gelen name burada kullanılıyor */}
            </Typography>
          </Stack>
          <MenuButton showBadge onClick={() => setNotificationsOpen(true)}>
            <NotificationsRoundedIcon />
          </MenuButton>
        </Stack>
        <Divider />
        <Stack sx={{ flexGrow: 1 }}>
          <MenuContent onSelectPage={onSelectPage} />
          <Divider />
        </Stack>
        <CardAlert toggleDrawer={toggleDrawer} />
        <Stack sx={{ p: 2 }}>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<PersonIcon />}
            onClick={handleProfile}
          >
            Profil
          </Button>
        </Stack>
        <Stack sx={{ p: 2 }}>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<LogoutRoundedIcon />}
            onClick={handleLogout}
          >
            Çıkış Yap
          </Button>
        </Stack>
      </Stack>

      {/* Bildirim Dialog'u */}
      <Dialog
        open={notificationsOpen}
        onClose={() => setNotificationsOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Bildirimler</DialogTitle>
        <DialogContent sx={{ maxHeight: 400, overflowY: 'auto' }}>
        <List>
          {mockNotifications.length === 0 ? (
            <ListItem>
              <ListItemText primary="Henüz bir bildiriminiz yok." />
            </ListItem>
          ) : (
            mockNotifications.map((notification, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText primary={notification} />
                </ListItem>
                {index < mockNotifications.length - 1 && <Divider />}
              </React.Fragment>
            ))
          )}
        </List>

        </DialogContent>
        <Button onClick={() => setNotificationsOpen(false)} color="primary">
          Kapat
        </Button>
      </Dialog>
    </Drawer>
  );
}
