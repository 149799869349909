import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import { useNavigate } from 'react-router-dom';

interface CardAlertProps {
  toggleDrawer?: (newOpen: boolean) => () => void; // Opsiyonel yaptık
}

export default function CardAlert({ toggleDrawer }: CardAlertProps) {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/sales');
    if (toggleDrawer) {
      toggleDrawer(false)(); // toggleDrawer varsa menüyü kapat
    }
  };

  return (
    <Card variant="outlined" sx={{ m: 1.5, p: 1.5 }}>
      <CardContent>
        <AutoAwesomeRoundedIcon fontSize="small" />
        <Typography gutterBottom sx={{ fontWeight: 600 }}>
          Akıllı Yorum Çözümü
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
          Google yorumlarınızı NFC kart ile toplayın, yapay zeka ile yanıtlayın ve detaylı analiz raporları alın.
        </Typography>
        <Button variant="contained" size="small" fullWidth onClick={handleButtonClick}>
          Satış Sayfasına Git
        </Button>
      </CardContent>
    </Card>
  );
}
