import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomizedDataGrid from '../components/cardsPage/CustomizedDataGrid';
//import PageViewsBarChart from '../components/cardsPage/PageViewsBarChart';
//import PageViewsBarChart from '../components/PageViewsBarChart';
//import SessionsChart from '../components/cardsPage/SessionsChart';

export default function Cards() {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* cards */}
{/*
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        <Grid size={{xs:12, sm: 12, md: 6 }}>
          <SessionsChart />
        </Grid>
        <Grid size={{xs:12, sm: 12, md: 6 }}>
          <PageViewsBarChart />
        </Grid>
      </Grid>
      */}
      <Grid container spacing={2} columns={12}>
        <Grid sx={{ width: '100%',minHeight: '400px' }} size={{ md: 12, lg: 12 }}>
          <CustomizedDataGrid />
        </Grid>
      </Grid>
      {/* <Copyright sx={{ my: 4 }} /> */}
    </Box>
  );
}
