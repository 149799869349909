import * as React from 'react';
import { Card, Typography, Avatar, Box } from '@mui/material';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  maxWidth: 280,
  margin: theme.spacing(2, 0),
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 36,
  height: 36,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.divider}`,
}));

export default function BusinessCard() {
  //const rating = 4.6;
  const [businessName, setBusinessName] = React.useState('');
  const [latest_rating, setLatestRating] = React.useState('');

  const [address, setAddress] = React.useState('');

  React.useEffect(() => {
    const latest_rating = localStorage.getItem('latest_rating');
    const storedBusinessName = localStorage.getItem('business_name');
    const storedAddress = localStorage.getItem('address');
    if (latest_rating) setLatestRating(latest_rating);
    if (storedBusinessName) setBusinessName(storedBusinessName);
    if (storedAddress) {
      // Adresi parçalarına ayır ve sadece son iki kısmı al
      const addressParts = storedAddress.split(' ');
      const shortAddress = addressParts.slice(-2).join(' ').trim();
      setAddress(shortAddress);
    }
  }, []);

  const renderStars = (rating: number) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {Array.from({ length: fullStars }, (_, index) => (
          <StarIcon key={`full-${index}`} sx={{ color: 'gold', fontSize: 18 }} />
        ))}
        {halfStar && <StarHalfIcon key="half" sx={{ color: 'gold', fontSize: 18 }} />}
        {Array.from({ length: emptyStars }, (_, index) => (
          <StarOutlineIcon key={`empty-${index}`} sx={{ color: 'gold', fontSize: 18 }} />
        ))}
      </>
    );
  };

  return (
    <StyledCard>
      <StyledAvatar>
        <StorefrontOutlinedIcon />
      </StyledAvatar>
      <Box ml={2} flex="1">
        <Typography variant="subtitle1" fontWeight="bold">
          {businessName || 'İşletme Adı'}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {address || 'Adres Bilgisi'}
        </Typography>
        <Box display="flex" alignItems="center" mt={0.5}>
          {renderStars(parseFloat(latest_rating))}
          <Typography variant="body2" color="text.secondary" ml={0.5}>
            {latest_rating}/5
          </Typography>
        </Box>
      </Box>
    </StyledCard>
  );
}
