import { fetchWithAuth } from './fetchWrapper';

export async function signIn(email: string, password: string) {
    // Giriş işlemi
    const response = await fetchWithAuth('/auth/login', {
        method: 'POST',
        body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
        throw new Error(`Login failed: ${response.statusText}`);
    }

    const data = await response.json();
    localStorage.setItem('SEND_TOKEN_TO_EXTENSION', data.token);
    localStorage.setItem('refresh_token', data.refresh_token);

    const token = data.token;
    
    window.postMessage({ type: 'SEND_TOKEN_TO_EXTENSION', token }, '*');

    return { token: data.token, refreshToken: data.refresh_token };
}

export async function fetchProfile() {
    const response = await fetchWithAuth('/profile', { method: 'GET' });
    if (!response.ok) {
        throw new Error(`Profile fetch failed: ${response.statusText}`);
    }
    return response.json();
}

export async function fetchBusinessRating() {
    const response = await fetchWithAuth('/profile/business/rating', { method: 'GET' });
    if (!response.ok) {
        throw new Error(`Rating fetch failed: ${response.statusText}`);
    }
    return response.json();
}

export async function fetchBusinessRatingFromPlacesApi() {
    const response = await fetchWithAuth('/profile/business/places_api/rating', { method: 'GET' });
    if (!response.ok) {
        throw new Error(`Rating fetch failed: ${response.statusText}`);
    }
    return response.json();
}