import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/tr'; // Türkçe dil desteğini yükler
import './CustomDatePicker.css';

moment.locale('tr'); // Türkçe olarak ayarla

const Heading = ({ date, changeMonth, resetDate }) => (
  <nav className="calendar--nav">
    <button onClick={() => changeMonth(date.month() - 1)}>&#8249;</button>
    <h1 onClick={() => resetDate()}>{date.format('MMMM')} <small>{date.format('YYYY')}</small></h1>
    <button onClick={() => changeMonth(date.month() + 1)}>&#8250;</button>
  </nav>
);

const Day = ({ currentDate, date, startDate, endDate, onClick }) => {
  let className = 'calendar__day';

  if (moment().isSame(date, 'day')) {
    className += ' calendar__day--today';
  }

  if (date.isSame(startDate, 'day')) {
    className += ' calendar__day--start';
  }

  if (date.isBetween(startDate, endDate, 'day')) {
    className += ' calendar__day--between';
  }

  if (date.isSame(endDate, 'day')) {
    className += ' calendar__day--end';
  }

  if (!date.isSame(currentDate, 'month')) {
    className += ' calendar__day--muted';
  }

  return (
    <span onClick={() => onClick(date)} className={className}>
      {date.date()}
    </span>
  );
};

const Days = ({ date, startDate, endDate, onClick }) => {
  const daysInMonth = moment(date).daysInMonth();
  const firstDayOfMonth = moment(date).startOf('month').day();
  const previousMonthDays = moment(date).subtract(1, 'month').daysInMonth();
  const nextMonth = moment(date).add(1, 'month');

  const days = [];

  // Geçen ayın günleri
  for (let i = firstDayOfMonth - 1; i > 0; i--) {
    days.push(moment(date).subtract(1, 'month').date(previousMonthDays - i + 1));
  }

  // Bu ayın günleri
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(moment(date).date(i));
  }

  // Gelecek ayın günleri
  for (let i = 1; days.length < 42; i++) {
    days.push(moment(nextMonth).date(i));
  }

  return (
    <div className="calendar--days">
      {['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'].map((day, i) => (
        <span className="calendar--day-label" key={i}>{day}</span>
      ))}
      {days.map((day, i) => (
        <Day
          key={i}
          currentDate={date}
          date={day}
          startDate={startDate}
          endDate={endDate}
          onClick={onClick}
        />
      ))}
    </div>
  );
};

const CustomDatePicker = ({ onDatePick }) => {
  const [date, setDate] = useState(moment());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const resetDate = () => setDate(moment());

  const changeMonth = (month) => setDate(moment(date).month(month));

  const handleDateClick = (selectedDate) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(selectedDate);
      setEndDate(null);
      onDatePick({ startDate: selectedDate, endDate: null });
    } else if (selectedDate.isBefore(startDate)) {
      setStartDate(selectedDate);
      onDatePick({ startDate: selectedDate, endDate });
    } else {
      setEndDate(selectedDate);
      onDatePick({ startDate, endDate: selectedDate });
      setIsCalendarOpen(false); // İkinci tarihi seçtikten sonra otomatik kapanma
    }
  };

  // Filtre fonksiyonları
  const setLastWeek = () => {
    const start = moment().subtract(1, 'weeks').startOf('day');
    const end = moment().endOf('day');
    setStartDate(start);
    setEndDate(end);
    onDatePick({ startDate: start, endDate: end });
    setIsCalendarOpen(false);
  };

  const setLastMonth = () => {
    const start = moment().subtract(1, 'months').startOf('day');
    const end = moment().endOf('day');
    setStartDate(start);
    setEndDate(end);
    onDatePick({ startDate: start, endDate: end });
    setIsCalendarOpen(false);
  };

  const setLastYear = () => {
    const start = moment().subtract(1, 'years').startOf('day');
    const end = moment().endOf('day');
    setStartDate(start);
    setEndDate(end);
    onDatePick({ startDate: start, endDate: end });
    setIsCalendarOpen(false);
  };

  return (
    <div className="calendar-container">
      <button onClick={() => setIsCalendarOpen(!isCalendarOpen)}>
        {startDate ? startDate.format('YYYY-MM-DD') : 'Tarih Aralığı Seçiniz'}
        {startDate && endDate ? ' - ' : ''} {/* Boşluk ve tire ekleniyor */}
        {endDate ? endDate.format('YYYY-MM-DD') : ''}
      </button>

      {isCalendarOpen && (
        <div className="calendar">
          <Heading date={date} changeMonth={changeMonth} resetDate={resetDate} />
          <div className="calendar--filters">
            <button onClick={setLastWeek}>Son 1 Hafta</button>
            <button onClick={setLastMonth}>Son 1 Ay</button>
            <button onClick={setLastYear}>Son 1 Yıl</button>
          </div>
          <Days date={date} startDate={startDate} endDate={endDate} onClick={handleDateClick} />
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
