// src/api/reportApi.ts
export interface Report {
    id: string;
    title: string;
    date: string;
    type: 'weekly' | 'monthly';
  }
  
  // Mock API fonksiyonu
  export async function fetchReports(
    page: number,
    pageSize: number,
    reportType: 'weekly' | 'monthly',
  ): Promise<{ reports: Report[]; totalCount: number }> {

    const mockReports: Report[] = [
        // Aylık Raporlar
        //{ id: '1', title: 'Aylık Rapor 1', date: '2024-09-01', type: 'monthly' },
      
        // Haftalık Raporlar
        //{ id: '7', title: 'Haftalık Rapor 1', date: '2024-10-01', type: 'weekly' },
      ];
      
  
    // Filtreleme ve sayfalama işlemi
    const filteredReports = mockReports.filter(report => report.type === reportType);
    const totalCount = filteredReports.length;
    const start = (page - 1) * pageSize;
    const paginatedReports = filteredReports.slice(start, start + pageSize);
  
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ reports: paginatedReports, totalCount });
      }, 500); // Simule edilmiş gecikme
    });
  }
  