import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  useMediaQuery,
  useTheme,
  Snackbar,
  Alert,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockIcon from '@mui/icons-material/Lock';
import CancelIcon from '@mui/icons-material/Cancel';
import { getProfileData, updatePhone, updatePassword, cancelSubscription } from '../api/profileApi';

export default function ProfilePage() {
  const [profileData, setProfileData] = useState({
    phone: '',
    email: '',
    address: '',
    subscriptionPlan: '',
    products: [],
  });

  const [openPhoneEdit, setOpenPhoneEdit] = useState(false);
  const [openPasswordEdit, setOpenPasswordEdit] = useState(false);
  const [openCancelSubscription, setOpenCancelSubscription] = useState(false);
  const [newPhone, setNewPhone] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchData = async () => {
      const data = await getProfileData();
      setProfileData(data as any);
    };
    fetchData();
  }, []);

  const handlePhoneEditOpen = () => setOpenPhoneEdit(true);
  const handlePhoneEditClose = () => setOpenPhoneEdit(false);

  const handlePasswordEditOpen = () => setOpenPasswordEdit(true);
  const handlePasswordEditClose = () => setOpenPasswordEdit(false);

  const handleCancelSubscriptionOpen = () => setOpenCancelSubscription(true);
  const handleCancelSubscriptionClose = () => setOpenCancelSubscription(false);

  const handleSavePhone = async () => {
    try {
      const updatedPhone = await updatePhone(newPhone);
      setProfileData((prevData) => ({ ...prevData, phone: updatedPhone as string }));
      setNotification({ open: true, message: 'Telefon numarası başarıyla güncellendi.', severity: 'success' });
    } catch (error) {
      console.error('Telefon numarası güncellenirken bir hata oluştu:', error);
      setNotification({ open: true, message: 'Telefon numarası güncellenirken bir hata oluştu.', severity: 'error' });
    }
    setOpenPhoneEdit(false);
  };

  const handleSavePassword = async () => {
    await updatePassword(newPassword);
    setOpenPasswordEdit(false);
  };

  const handleConfirmCancelSubscription = async () => {
    await cancelSubscription();
    setProfileData((prevData) => ({ ...prevData, subscriptionPlan: 'Abonelik iptal edildi.' }));
    setOpenCancelSubscription(false);
  };

  const handleNotificationClose = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" fontWeight="bold" mb={3}>
        Profil Bilgileri
      </Typography>

      {/* İşletme Bilgileri */}
      <Card sx={{ mb: 3, p: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" color="primary" fontWeight="bold" gutterBottom>
            İşletme Bilgileri
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box display="flex" alignItems="center" mb={2}>
            <LocationOnIcon color="action" />
            <Typography ml={1}>{profileData.address || 'Adres bilgisi bulunamadı.'}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <PhoneIcon color="action" />
            <Typography ml={1}>{profileData.phone || 'Telefon bilgisi bulunamadı.'}</Typography>
            <Button size="small" startIcon={<EditIcon />} sx={{ ml: 'auto' }} onClick={handlePhoneEditOpen} />
          </Box>
          <Box display="flex" alignItems="center">
            <EmailIcon color="action" />
            <Typography ml={1}>{profileData.email || 'Email bilgisi bulunamadı.'}</Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Satın Alınan Ürünler ve Kullanım Hakları */}
      <Card sx={{ mb: 3, p: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" color="primary" fontWeight="bold" gutterBottom>
            Satın Alınan Ürünler ve Kullanım Hakları
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {profileData.products && profileData.products.length > 0 ? (
            profileData.products.map((product, index) => (
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} key={index}>
                <Typography>{product.name}</Typography>
                <Typography>{product.usage}</Typography>
              </Box>
            ))
          ) : (
            <Typography color="text.secondary">Ürün bulunamadı.</Typography>
          )}
        </CardContent>
      </Card>

      {/* Abonelik Bilgileri ve İptal Etme */}
      <Card sx={{ p: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" color="primary" fontWeight="bold" gutterBottom>
            Mevcut Abonelik
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography>{profileData.subscriptionPlan || 'Abonelik bilgisi bulunamadı.'}</Typography>
            <Button
              variant="outlined"
              color="error"
              startIcon={<CancelIcon />}
              onClick={handleCancelSubscriptionOpen}
              disabled={!profileData.subscriptionPlan} // Abonelik bilgisi yoksa butonu devre dışı bırakır
              sx={{
                '@media (max-width: 600px)': {
                  minWidth: 'unset',
                  padding: '10px',
                },
              }}
            >
              <Box display={{ xs: 'none', sm: 'inline' }}>Aboneliği İptal Et</Box>
              <Box display={{ xs: 'inline', sm: 'none' }}>İptal</Box>
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* Telefon Düzenleme Pop-up */}
      <Dialog
        open={openPhoneEdit}
        onClose={handlePhoneEditClose}
        fullWidth
        maxWidth={isMobile ? 'xl' : 'sm'}
      >
        <DialogTitle>Telefonu Değiştir</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Yeni Telefon Numarası"
            type="tel"
            fullWidth
            value={newPhone}
            onChange={(e) => setNewPhone(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePhoneEditClose} color="primary">
            İptal
          </Button>
          <Button onClick={handleSavePhone} color="primary" variant="contained">
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>

      {/* Abonelik İptal Etme Onay Pop-up */}
      <Dialog
        open={openCancelSubscription}
        onClose={handleCancelSubscriptionClose}
        fullWidth
        maxWidth={isMobile ? 'xl' : 'sm'}
      >
        <DialogTitle>Aboneliği İptal Et</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Aboneliğinizi iptal etmek istediğinizden emin misiniz? Abonelik iptali sonrası kalan haklarınızı kaybedebilirsiniz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelSubscriptionClose} color="primary">
            İptal
          </Button>
          <Button onClick={handleConfirmCancelSubscription} color="error" variant="contained">
            Evet, Aboneliğimi İptal Et!
          </Button>
        </DialogActions>
      </Dialog>

      {/* Notification */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert onClose={handleNotificationClose} severity={notification.severity as any} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
