import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import CreditCardIcon from '@mui/icons-material/CreditCard';
// import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import HomeIcon from '@mui/icons-material/Home';
import RateReviewIcon from '@mui/icons-material/RateReview';

interface MenuContentProps {
  onSelectPage: (page: string) => void;
}

export default function MenuContent({ onSelectPage }: MenuContentProps) {
  // `name` değeri localStorage'dan alınıyor
  const name = localStorage.getItem('business_name') || 'Feedup AI';

  // Ana liste elemanları
  const mainListItems = [
    { displayText: name, path: 'dashboard', icon: <HomeIcon /> },
    { displayText: 'Kartlarım', path: 'cards', icon: <CreditCardIcon /> },
    { displayText: 'Analizler', path: 'analytics', icon: <AnalyticsRoundedIcon /> },
    { displayText: 'Cevap Üretme', path: 'settings', icon: <RateReviewIcon /> },
  ];

  // İkincil liste elemanları
  const secondaryListItems = [
    { displayText: 'Paket Satın Al', path: 'sales', icon: <AssignmentRoundedIcon /> },
    { displayText: 'Sepetim', path: 'cart', icon: <ShoppingCartCheckoutIcon /> },
    { displayText: 'Hakkında', path: 'about', icon: <InfoRoundedIcon /> },
    { displayText: 'Geri Bildirim & Destek', path: 'feedback', icon: <HelpRoundedIcon /> },
  ];

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton onClick={() => onSelectPage(item.path)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.displayText} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton onClick={() => onSelectPage(item.path)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.displayText} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
