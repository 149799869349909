import * as React from 'react';
import { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Rating, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { fetchRatings, fetchScanStats, fetchUrgentIssues } from '../api/dashboardApi';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  height: '100%',
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
}));

const DashboardContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  justifyContent: 'space-between',
});

export default function Dashboard() {
  const [ratings, setRatings] = useState(null);
  const [scanStats, setScanStats] = useState(null);
  const [urgentIssues, setUrgentIssues] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const getData = async () => {
      const ratingsData = await fetchRatings();
      const scanStatsData = await fetchScanStats();
      const urgentIssuesData = await fetchUrgentIssues();

      setRatings(ratingsData);
      setScanStats(scanStatsData);
      setUrgentIssues(urgentIssuesData);
    };
    getData();
  }, []);

  const renderGrowth = (value) => (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      {value > 0 ? (
        <ArrowDropUpIcon fontSize="small" sx={{ color: 'green' }} />
      ) : value < 0 ? (
        <ArrowDropDownIcon fontSize="small" sx={{ color: 'red' }} />
      ) : null}
      <Typography
        variant="body2"
        fontWeight="bold"
        color={value > 0 ? 'green' : value < 0 ? 'red' : 'text.secondary'}
      >
        {value}
      </Typography>
    </Box>
  );

  if (!ratings || !scanStats || !urgentIssues) {
    return <Typography>Yükleniyor...</Typography>;
  }

  return (
    <DashboardContainer sx={{ p: 2 }}>
      {/* Google Rating Card */}
      <Box flexBasis={{ xs: '100%', sm: '48%' }} mb={2}>
        <StyledCard>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} mb={2}>
              <Box display="flex" alignItems="center">
                <PinDropIcon fontSize="medium" sx={{ mr: 1 }} />
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                  Google Puanı
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Rating value={parseFloat(ratings.overallRating)} readOnly precision={0.5} size="small" />
                <Typography variant="h5" ml={1} fontWeight="bold">
                  {ratings.overallRating}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography>Son 1 Ay</Typography>
              {renderGrowth(ratings.oneMonthGrowth)}
            </Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography>Son 6 Ay</Typography>
              {renderGrowth(ratings.sixMonthGrowth)}
            </Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography>Toplam Artış</Typography>
              {renderGrowth(ratings.totalGrowth)}
            </Box>
          </CardContent>
        </StyledCard>
      </Box>

      {/* Card Scan Growth Card */}
      <Box flexBasis={{ xs: '100%', sm: '48%' }} mb={2}>
        <StyledCard>
          <CardContent>
            <Typography variant="h5" fontWeight="bold">
              Kart Okutma İstatistikleri
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography>Toplam Unique Scan:</Typography>
              <Typography fontWeight="bold">{scanStats.total_unique_client_count}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography>Bu Ayın Tıklanma Sayısı:</Typography>
              <Typography fontWeight="bold">{scanStats.this_month_unique_client_count}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography>Geçen Ayın Tıklanma Sayısı:</Typography>
              <Typography fontWeight="bold">{scanStats.last_month_unique_client_count}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography>Geçen Aya Göre Değişim:</Typography>
              {renderGrowth(scanStats.change)}
            </Box>
          </CardContent>
        </StyledCard>
      </Box>

      {/* Urgent Issues Card */}
      <Box flexBasis={{ xs: '100%', sm: '48%' }} mb={2}>
        <StyledCard>
          <CardContent>
            <Typography variant="h6" fontWeight="bold">
              Acil Önlemler
            </Typography>
            <ul>
              {urgentIssues.urgentIssues.map((issue, index) => (
                <li key={index}>
                  <Typography>{issue}</Typography>
                </li>
              ))}
            </ul>
          </CardContent>
        </StyledCard>
      </Box>

      {/* Promotional Video Card */}
      <Box flexBasis={{ xs: '100%', sm: '48%' }} mb={2}>
        <StyledCard>
          <CardContent>
          <Box
            style={{
              position: 'relative',
              width: '100%',
              height: '300px', // Yüksekliği sabitleyin veya dinamik yapabilirsiniz
              backgroundImage: 'url(/feedup_nfc2.png)', // Resim yolu
              backgroundSize: 'contain', // Resmi kırpmadan sığdırır
              backgroundRepeat: 'no-repeat', // Tekrarlamayı önler
              backgroundPosition: 'center', // Ortalar
              backgroundColor: 'white', // Boş alanları beyaz yapar
            }}
          ></Box>
          </CardContent>
        </StyledCard>
      </Box>

    </DashboardContainer>
  );
}
