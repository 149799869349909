import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardMedia, CardActions, Typography, Button } from '@mui/material';
import { addToCart, removeOneItem, removeFromCart, getCartFromLocalStorage } from '../../helpers/localStorageCart';

const DEFAULT_IMAGE_URL = '/feedup_nfc.jpg';

const PackageCard = ({ product, setCartUpdateTrigger }) => {
  const [cartQuantity, setCartQuantity] = useState(0);

  useEffect(() => {
    const cart = getCartFromLocalStorage();
    const itemInCart = cart.find((item) => item.sku === product.sku);
    setCartQuantity(itemInCart ? itemInCart.quantity : 0);
  }, [product.sku]);

  const handleAddToCart = () => {
    const updatedCart = addToCart(product);
    const updatedQuantity = updatedCart.find((item) => item.sku === product.sku)?.quantity || 0;
    setCartQuantity(updatedQuantity);
    setCartUpdateTrigger((prev) => prev + 1);
  };

  const handleRemoveOneItem = () => {
    const updatedCart = removeOneItem(product.sku);
    const updatedQuantity = updatedCart.find((item) => item.sku === product.sku)?.quantity || 0;
    setCartQuantity(updatedQuantity);
    setCartUpdateTrigger((prev) => prev + 1);

  };

  const handleRemoveFromCart = () => {
    removeFromCart(product.sku);
    setCartQuantity(0); // Item completely removed from cart
  };

  const getPrice = () => {
    if (Array.isArray(product.prices)) {
      const priceObj = product.prices.find((p) => p.currencyType === 'try');
      return priceObj?.value ?? 'Fiyat bulunamadı';
    } else if (typeof product.prices === 'object' && product.prices.currencyType === 'try') {
      return product.prices.value ?? 'Fiyat bulunamadı';
    }
    return 'Fiyat bulunamadı';
  };

  return (
    <Card
      variant="outlined"
      sx={{ borderRadius: 2, boxShadow: 3, height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <CardMedia
        component="img"
        height="220"
        image={product.img || DEFAULT_IMAGE_URL}
        alt={product.name}
      />
      <CardContent sx={{ textAlign: 'center', flexGrow: 1 }}>
        <Typography variant="h5" color="primary" fontWeight="bold">
          {product.name}
        </Typography>
        <Typography variant="h6" color="secondary" fontWeight="bold" mt={2}>
          ₺{getPrice()}
        </Typography>
        <Typography variant="body2" color="text.secondary" mt={1}>
          {product.description}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
        <Button onClick={handleRemoveOneItem} disabled={cartQuantity === 0}>
          -
        </Button>
        <Typography variant="body2">{cartQuantity}</Typography>
        <Button onClick={handleAddToCart}>+</Button>
      </CardActions>
    </Card>
  );
};

export default PackageCard;
