import * as React from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  MenuItem,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchSettings, saveSettings, Settings as SettingsType } from '../api/settingsApi';

const Section = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: '1px solid rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

// İngilizce -> Türkçe yanıt tarzı eşleme
const tonalityMapping = {
  Formal: "Resmi (🎩)",
  Professional: "Profesyonel (📚)",
  Friendly: "Samimi (😊)",
  Concise: "Kısa ve Net (✔️)",
  Detailed: "Detaylı (📋)"
};

export default function Settings() {
  const [tonality, setTonality] = React.useState('Formal'); // Varsayılan olarak "Formal"
  const [signature, setSignature] = React.useState(''); // Varsayılan olarak boş
  const [notification, setNotification] = React.useState({ open: false, message: '', severity: 'success' });

  React.useEffect(() => {
    const loadSettings = async () => {
      try {
        const settings: SettingsType = await fetchSettings();
        setTonality(settings.tonality || "Formal"); // Eğer `tonality` boşsa "Formal" olarak ayarla
        setSignature(settings.signature || ""); // Eğer `signature` boşsa boş bırak
      } catch (error) {
        console.error("Settings could not be fetched. Using default values.", error);
        // Hata durumunda varsayılan değerleri koruyarak devam et
      }
    };
    loadSettings();
  }, []);

  const handleSave = async () => {
    try {
      const settings: SettingsType = {
        tonality,
        signature,
      };
      await saveSettings(settings);
      // Başarılı bir şekilde kaydedildiğinde kullanıcıya bildirim göster
      setNotification({ open: true, message: 'Ayarlar başarıyla kaydedildi.', severity: 'success' });
    } catch (error) {
      console.error('Ayarlar kaydedilirken bir hata oluştu:', error);
      setNotification({ open: true, message: 'Ayarlar kaydedilirken bir hata oluştu.', severity: 'error' });
    }
  };

  const handleNotificationClose = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
          Akıllı Cevap Üretme
        </Typography>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Kaydet
        </Button>
      </Box>

      <Section>
        <Typography variant="h6" sx={{ color: 'text.secondary', fontWeight: 'medium' }}>
          Yanıt Tarzı
        </Typography>
        <TextField
          select
          value={tonality}
          onChange={(e) => setTonality(e.target.value)}
          fullWidth
        >
          {/* İngilizce API değerlerini Türkçe karşılıklarıyla listeleme */}
          {Object.entries(tonalityMapping).map(([key, label]) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Section>

      <Section>
        <Typography variant="h6" sx={{ color: 'text.secondary', fontWeight: 'medium' }}>
          İmza Ekleme
        </Typography>
        <TextField
          variant="standard"
          fullWidth
          multiline
          rows={3}
          value={signature}
          onChange={(e) => setSignature(e.target.value)}
          placeholder="İmza metninizi buraya yazın"
        />
      </Section>

      {/* Notification */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert onClose={handleNotificationClose} severity={notification.severity as any} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>

      {/* Yeni Kısım */}
      <Section>
        <Typography variant="h6" sx={{ color: 'text.secondary', fontWeight: 'medium' }}>
          Chrome Web Store Üzerinden Cevap Verme Eklentisini Kurun
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href="https://chromewebstore.google.com/detail/feedup-ai-review-responde/clijejfkdnkgoifamkmpfhlkinlgaccb" // İndirme bağlantısı buraya eklenecek
          target="_blank"
          rel="noopener noreferrer"
        >
          Eklentiyi Kur
        </Button>
      </Section>

      <Section>
        <Typography variant="h6" sx={{ color: 'text.secondary', fontWeight: 'medium' }}>
          Nasıl Çalışır?
        </Typography>
        <img
          src="/ext.jpg"
          alt="Ürünün çalışma ekran görüntüsü"
          style={{
            maxWidth: '100%',
            height: 'auto',
            borderRadius: 8,
            border: '1px solid rgba(0, 0, 0, 0.1)',
          }}
        />
        <Typography sx={{ mt: 2 }}>
          Yukarıdaki ekran görüntüsünde ürünün nasıl çalıştığını inceleyebilirsiniz. Daha fazla bilgi için destek sayfamızı ziyaret edin.
        </Typography>
      </Section>
    </Container>
  );
}
