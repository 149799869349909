import { fetchWithAuth } from '../api/fetchWrapper';

export const submitFeedback = async (feedbackType: string, feedbackText: string, surveyResponse?: number) => {
  try {
    const url = '/contact/feedback/';
    const body = JSON.stringify({
      type: feedbackType,
      text: feedbackText,
      rating: surveyResponse,
    });

    const response = await fetchWithAuth(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Geri bildirim gönderilirken bir hata oluştu.');
    }

    return { message: 'Geri bildiriminiz başarıyla gönderildi!' };
  } catch (error) {
    console.error('API Hatası:', error);
    throw error;
  }
};
