import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardMedia, CardActions, Typography, Button, Tabs, Tab } from '@mui/material';
import { addToCart, getCartFromLocalStorage } from '../../helpers/localStorageCart';

const DEFAULT_IMAGE_URL = '/feedup_nfc.jpg';

const SubscriptionCard = ({ productGroup, setCartUpdateTrigger }) => {
  const [cartQuantity, setCartQuantity] = useState(0);
  const [pricingType, setPricingType] = useState('monthly');

  const selectedProduct = productGroup.find(
    (product) => product.prices?.paymentPeriod === pricingType
  );

  useEffect(() => {
    const cart = getCartFromLocalStorage();
    const itemInCart = cart.find((item) => item.sku === selectedProduct?.sku);
    setCartQuantity(itemInCart ? itemInCart.quantity : 0);
  }, [selectedProduct]);

  const handleAddToCart = () => {
    if (selectedProduct) {
      const updatedCart = addToCart(selectedProduct);
      const updatedQuantity = updatedCart.find((item) => item.sku === selectedProduct.sku)?.quantity || 0;
      setCartQuantity(updatedQuantity);
      setCartUpdateTrigger((prev) => prev + 1);
    }
  };

  const handlePricingTypeChange = (event, newValue) => {
    setPricingType(newValue);
  };

  return (
    <Card
      variant="outlined"
      sx={{ borderRadius: 2, boxShadow: 3, height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <CardMedia
        component="img"
        height="220"
        image={productGroup[0].img || DEFAULT_IMAGE_URL}
        alt={productGroup[0].name}
      />
      <CardContent sx={{ textAlign: 'center', flexGrow: 1 }}>
        <Typography variant="h5" color="primary" fontWeight="bold">
          {productGroup[0].name}
        </Typography>
        <Tabs value={pricingType} onChange={handlePricingTypeChange} centered>
          <Tab label="Aylık" value="monthly" />
          <Tab label="Yıllık" value="yearly" />
        </Tabs>
        <Typography variant="h6" color="secondary" fontWeight="bold" mt={2}>
          ₺{selectedProduct?.prices?.value || 0}
        </Typography>
        <Typography variant="body2" color="text.secondary" mt={1}>
          {productGroup[0].description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="primary" onClick={handleAddToCart} fullWidth disabled={!selectedProduct}>
          Satın Al
        </Button>
      </CardActions>
    </Card>
  );
};

export default SubscriptionCard;
